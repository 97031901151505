import React from 'react'
import Header from "../components/molecules/header";
import Footer from "../components/molecules/footer";
import Banner from '../components/molecules/banner';
import InformationApply from '../components/molecules/applyForm';
import Head from "../components/organisms/head";


export default function apply() {

    const moduleData = {
        title: "Freelancing opportunities are awaiting <br/><span>Apply now!</span>",
      };
      const data ={
        title:'Apply Now!',
        description:' Use this application form to apply for free training courses to further your skills.',
        image:'',
        keywords:'apply now,apply you,apply now at,now apply,apply now for,apply now to,applied now,apply for now, i apply now'
    }
  return (
    <div>
      <Head data={data} url={'apply/'}/>
        <Header />
        <div id="apply-now-banner">
          <Banner moduleData={moduleData} template="apply" />
        </div>
        <InformationApply />
        <Footer  noApply={true}/>
    </div>
  )
}
