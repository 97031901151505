import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import styles from "./style.module.scss";
import Button from "../../atoms/Button";
import bgImg from "../../../assets/images/categoryBanner.jpg"
import AniLink from "gatsby-plugin-transition-link/AniLink";

export default function index({ moduleData, template, bg = false, imgUrl=null }) {
  const extraClassName = template?.toLowerCase().replace(/\s/g, "");
  const image = getImage(moduleData?.image?.localFile);
  const backgorund = imgUrl ? imgUrl : bgImg;
  const buttons = () => {
    switch (template) {
      case "Home":
        return (
          <div className={styles.btnBanner}>
            <AniLink
              paintDrip
              top="entry"
              entryOffset={80}
              hex="#518ef8"
              duration={0.6}
              to="/contact-us"
              style={{ textDecoration: "none" }}
            >
            <Button to="/contact-us" text="Partner with us" className={styles.coursesBtn} />
            </AniLink>
          </div>
        );
      case "singleCategory":
        return (
          <div className={styles.buttonsCat}>
            <AniLink
              paintDrip
              top="entry"
              entryOffset={80}
              hex="#518ef8"
              duration={0.6}
              to="/apply"
              style={{ textDecoration: "none" }}
            >
              <Button text="Apply now" to="/apply" />
            </AniLink>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div
      className={`${styles.containerBanner} ${styles[extraClassName]}`}
      style={{ backgroundImage: bg ? `url(${backgorund})` : null }}
    >
      <div className={styles.container}>
        <div className={styles.title}>
          <h1 dangerouslySetInnerHTML={{ __html: moduleData?.title }} />
          <p className={styles.info} style={{color:'white', width:"30%"}}>{moduleData?.info}</p>
        </div>
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: moduleData?.description }}
        />
        {buttons()}
        <div className={styles.img}>
          {(moduleData?.image || moduleData?.image === "static") && (
            <GatsbyImage
              image={image}
              alt={
                moduleData?.image?.altText
                  ? moduleData?.image?.altText
                  : "Leranio.eu"
              }
              loading="lazy"
            />
          )}
        </div>
      </div>
    </div>
  );
}
