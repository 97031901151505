import React from "react";

export default function correct() {
  return (
    <div>
      <svg
        id="correct"
        xmlns="http://www.w3.org/2000/svg"
        width="21.403"
        height="21.403"
        viewBox="0 0 21.403 21.403"
      >
        <path
          id="Path_291"
          data-name="Path 291"
          d="M10.7,0A10.7,10.7,0,1,0,21.4,10.7,10.714,10.714,0,0,0,10.7,0Zm0,0"
          fill="#0bbe42"
        />
        <path
          id="Path_292"
          data-name="Path 292"
          d="M151.546,166.851l-5.8,5.8a.891.891,0,0,1-1.261,0l-2.9-2.9a.892.892,0,0,1,1.261-1.261l2.268,2.268,5.166-5.166a.892.892,0,0,1,1.261,1.261Zm0,0"
          transform="translate(-135.42 -158.417)"
          fill="#fff"
        />
      </svg>
    </div>
  );
}
